import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import { EMPTY_FUNCTION } from '@sbiz/common';

import { Feedback } from '../components/atoms';
import { DEFAULT_FEEDBACK_SEVERITY, FeedbackProps } from '../components/atoms/Feedback';

export type RootFeedbackProps = Omit<FeedbackProps, 'name' | 'onClose' | 'open'>;

const RootFeedbackContext = createContext<{
  close: () => void;
  open: (name: string, props?: RootFeedbackProps) => void;
}>({
  close: EMPTY_FUNCTION,
  open: EMPTY_FUNCTION,
});

export function RootFeedbackContextProvider({ children }: { children: ReactNode }) {
  const [feedbackProps, setFeedbackProps] = useState<Omit<FeedbackProps, 'onClose'>>(() => ({ name: '', open: false }));

  const close = useCallback(() => {
    setFeedbackProps((currentValue) => ({ ...currentValue, open: false }));
  }, []);

  const open = useCallback((name: string, props?: RootFeedbackProps) => {
    setFeedbackProps((currentValue) => ({
      ...currentValue,
      name,
      open: true,
      severity: DEFAULT_FEEDBACK_SEVERITY,
      ...props,
    }));
  }, []);

  const value = useMemo(() => ({ close, open }), [close, open]);

  return (
    <RootFeedbackContext.Provider value={value}>
      {children}
      <Feedback onClose={close} {...feedbackProps} />
    </RootFeedbackContext.Provider>
  );
}

export function useRootFeedbackContext() {
  return useContext(RootFeedbackContext);
}
